<template>
  <div class="animated fadeIn">
    <v-row class="justify-content-center">
      <v-col cols="11">
        <v-card-title style="background-color: transparent !important">
          <v-row>
            <v-col cols="12" md="6">
              <h4>ثبت فیش حقوقی</h4>
            </v-col>
            <v-col cols="12" md="6" class="text-end"
              ><v-btn class="secondary-btn pa-5" @click="$router.go(-1)">
                <span>
                  بازگشت
                  <v-icon class="ms-1">mdi-arrow-left</v-icon>
                </span>
              </v-btn></v-col
            >
          </v-row>
        </v-card-title>
        <v-card class="cards pa-6">
          <div v-if="!showForm">
            <div>
              <h6>
                <v-icon color="primary">mdi-flare</v-icon>
                تاریخ شروع و پایان فیش حقوقی را وارد کنید:
              </h6>
            </div>
            <v-row class="mt-2">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateFrom"
                    label=" تاریخ از "
                    :editable="true"
                    class="date-input"
                    hide-details
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateFrom"
                  element="dateFrom"
                  color="#00a7b7"
                /> </v-col
              ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateTo"
                    label=" تاریخ تا "
                    :editable="true"
                    class="date-input"
                    hide-details
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateTo"
                  element="dateTo"
                  color="#00a7b7"
                  :min="dateFrom"
                />
              </v-col>
              <v-row class="mb-4 me-1">
                <v-col>
                  <v-btn
                    class="float-end primary-btn submit-btn"
                    @click="getData()"
                    :loading="gettingData"
                    >اعمال</v-btn
                  >
                </v-col>
              </v-row>
            </v-row>
          </div>
          <div v-else>
            <h5 class="mb-4">اطلاعات فیش</h5>
            <v-row>
              <v-col cols="12">
                <div class="disabled-fields grey lighten-3">
                  <h6>نام و نام‌ خانوادگی : {{ mainData.name }}</h6>
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تعداد روز کارکرد: {{ mainData.workDays }}</h6>
                </div></v-col
              >
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تعداد ساعات کارکرد: {{ mainData.workHours }}</h6>
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تاریخ شروع فیش حقوقی: {{ mainData.startDate }}</h6>
                </div></v-col
              >
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تاریخ پایان فیش حقوقی: {{ mainData.endDate }}</h6>
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="mainData.maritalStatus"
                  :items="maritalStatuses"
                  item-text="text"
                  item-value="value"
                  label="وضعیت تاهل"
                  outlined
                  dense
                  hide-details
                ></v-select
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="mainData.maritalStatus == 'married'"
                  v-model="mainData.numberOfChildren"
                  label="تعداد فرزند"
                  type="number"
                  outlined
                  dense
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
            <hr class="my-4" />
            <h5 class="mb-4">حقوق و مزایا</h5>
            <div class="py-8 ps-16 pe-16 rounded-lg cyan lighten-5">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حقوق پایه ساعتی
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(mainData.salaryPerHour).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حقوق ماهیانه
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>{{ Number(mainData.salary).toLocaleString() }}</h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حق مسکن
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="paySlipData.houseFee"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    کمک هزینه اقلام مصرفی خانوار
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="paySlipData.familySupplements"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    اضافه کاری
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="paySlipData.overTime"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6 class="font-weight-bold">
                    <v-icon>mdi-cash-check</v-icon>
                    جمع حقوق و مزایای مشمول بیمه
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>{{ computedCoveredByInsurance.toLocaleString() }}</h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حق اولاد
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="paySlipData.childrenFee"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6 class="font-weight-bold">
                    <v-icon>mdi-cash-check</v-icon>
                    جمع حقوق و مزایای مشمول مالیات
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>{{ computedCoveredByTax.toLocaleString() }}</h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حق ماموریت
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="paySlipData.missionFee"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <hr class="my-5" />
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h5 class="font-weight-bold">
                    <v-icon>mdi-cash-plus</v-icon>
                    کل حقوق و مزایا
                  </h5></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h5>{{ computedTotalSalary.toLocaleString() }}</h5>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
            </div>
            <hr class="my-4" />
            <h5 class="mb-4">کسورات</h5>
            <div class="py-8 ps-16 pe-16 rounded-lg orange lighten-4">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    مالیات بر حقوق
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="paySlipData.taxFee"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    وام
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="paySlipData.loan"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    مساعده
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="paySlipData.imprest"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    7% سهم بیمه کارگر
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>{{ computedWorkerFee.toLocaleString() }}</h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    غیبت
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="paySlipData.absence"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    سایر کسورات
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="paySlipData.otherFees"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <hr class="my-5" />
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h5 class="font-weight-bold">
                    <v-icon>mdi-cash-plus</v-icon>
                    کل کسورات
                  </h5></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h5>{{ computedTotalDeductions.toLocaleString() }}</h5>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
            </div>
            <div class="mt-4 py-8 ps-16 pe-16 rounded-lg green lighten-4">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h5 class="font-weight-bold">
                    <v-icon class="teal--text text--darken-2"
                      >mdi-check-decagram</v-icon
                    >
                    خالص پرداختی
                  </h5></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h5 dir="ltr">
                      {{ computedTotalAmount.toLocaleString() }}
                    </h5>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
            </div>
            <div class="mt-4 text-end">
              <v-btn
                class="green-btn"
                width="150"
                height="50"
                @click="submitPaySlip()"
                :loading="submitLoading"
              >
                <v-icon class="me-1">mdi-check-underline</v-icon>
                <h6>صدور فیش</h6>
              </v-btn>
            </div>
          </div>
        </v-card></v-col
      ></v-row
    >
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      dateFrom: moment(new Date()).subtract(30, "day").format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      gettingData: false,
      paySlipData: {
        houseFee: 0,
        familySupplements: 0,
        overTime: 0,
        childrenFee: 0,
        missionFee: 0,
        taxFee: 0,
        loan: 0,
        imprest: 0,
        otherFees: 0,
        absence: 0,
      },
      moment: moment,
      showForm: false,
      maritalStatuses: [
        { value: "married", text: "متاهل" },
        { value: "single", text: "مجرد" },
      ],
      submitLoading: false,
      mainData: {},
    };
  },
  computed: {
    computedCoveredByInsurance() {
      return (
        Number(this.mainData.salaryPerHour) +
        Number(this.mainData.salary) +
        Number(this.paySlipData.houseFee) +
        Number(this.paySlipData.familySupplements) +
        Number(this.paySlipData.overTime)
      );
    },
    computedCoveredByTax() {
      return (
        Number(this.paySlipData.childrenFee) + this.computedCoveredByInsurance
      );
    },
    computedTotalSalary() {
      return Number(this.paySlipData.missionFee) + this.computedCoveredByTax;
    },
    computedWorkerFee() {
      return 0.07 * this.computedCoveredByInsurance;
    },
    computedTotalDeductions() {
      return (
        Number(this.paySlipData.taxFee) +
        Number(this.paySlipData.loan) +
        Number(this.paySlipData.imprest) +
        Number(this.paySlipData.absence) +
        Number(this.paySlipData.otherFees) +
        this.computedWorkerFee
      );
    },
    computedTotalAmount() {
      return this.computedTotalSalary - this.computedTotalDeductions;
    },
  },
  methods: {
    getData() {
      this.gettingData = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employee/payslipInfo/",
          {
            userId: this.$route.params.id,
            startDate: this.dateFrom,
            endDate: this.dateTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.mainData = res.data;
          this.showForm = true;
          this.gettingData = false;
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
          this.gettingData = false;
        });
    },
    submitPaySlip() {
      this.submitLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/payslip/new",
          {
            userId: this.$route.params.id,
            name: this.mainData.name,
            startDate: this.mainData.startDate,
            endDate: this.mainData.endDate,
            maritalStatus: this.mainData.maritalStatus,
            workDays: this.mainData.workDays,
            workHours: this.mainData.workHours,
            numberOfChildren:
              this.mainData.maritalStatus == "married"
                ? Number(this.mainData.numberOfChildren)
                : 0,
            salaryPerHour: this.mainData.salaryPerHour,
            salary: this.mainData.salary,
            houseFee: Number(this.paySlipData.houseFee),
            familySupplements: Number(this.paySlipData.familySupplements),
            overTime: Number(this.paySlipData.overTime),
            childrenFee: Number(this.paySlipData.childrenFee),
            missionFee: Number(this.paySlipData.missionFee),
            taxFee: Number(this.paySlipData.taxFee),
            loan: Number(this.paySlipData.loan),
            imprest: Number(this.paySlipData.imprest),
            workerRightFee: Number(this.computedWorkerFee),
            absence: Number(this.paySlipData.absence),
            otherFees: Number(this.paySlipData.otherFees),
            paidAmount: Number(this.computedTotalAmount),
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.submitLoading = false;
          if (res.status == 200) {
            this.toast(res.data.message, "success");
            localStorage.setItem("previousPage", "newPaySlip");
            this.$router.go(-1);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
